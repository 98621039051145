
import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { ErrorResponseModel } from '@/model/StaffManager';
import UserBaseInfoForm from '@/components/User/UserBaseInfoForm.vue';
import PasswordMeter from '@/components/Parts/PasswordMeter.vue';
import EngineerInfo from '@/components/User/EngineerInfo.vue';
import UserBaseInfo from '@/model/user/UserBaseInfo';
import UserRegistApi from '@/module/api/UserRegistApi';
import Password from '@/model/user/Password';
import MapApi from '@/module/api/MapApi';
import BackButton from '@/components/Parts/BackButton.vue';

@Component({
  components: {
    UserBaseInfoForm,
    EngineerInfo,
    PasswordMeter,
    BackButton
  }
})
export default class SignUp extends Vue {
  confirmPassword: Password = new Password();
  userBaseInfo: UserBaseInfo = new UserBaseInfo();
  provisionalRegistFlg: boolean = false;

  showDialog: boolean = false;
  message = '迷惑メール対策の設定状況によって、認証メール等が届かない場合があります。\n届かない場合、下記メールアドレスを受信できるように設定してみてください。\n『plsystem@person-labo.jp』';

  mounted(): void {
    this.showDialog = true;
  }

  /**
   * 登録ボタン押下
   */
  async regist(): Promise<void> {
    if (this.userBaseInfo.addressPerson.address.value) {
      const res = await MapApi.getLocation(this.userBaseInfo.addressPerson.address.value);
      this.userBaseInfo.addressLocation = res;
    }

    UserRegistApi.add(this.userBaseInfo).then(() => {
      this.provisionalRegistFlg = true;
    }).catch((error: AxiosError<ErrorResponseModel>) => {
      if (error.response?.data.code === 'DuplicateKey') {
        this.$root.$emit('event-show-snackbar-infinity', '既に使用されているメールアドレスです。');
        return;
      }
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました');
    });
  }

  /**
   * 登録ボタン活性判定
   */
  isValid(): boolean {
    // 名前
    if (this.userBaseInfo.username.value.length === 0) return true;

    // 名前フリガナ
    if (this.userBaseInfo.usernamekana.value.length === 0) return true;

    // メールアドレス
    if (this.userBaseInfo.emailcell.value.length === 0 && this.userBaseInfo.emailpc.value.length === 0) return true;

    // パスワード
    if (this.userBaseInfo.password.value.length < 6 || this.userBaseInfo.password.value.length > 36) return true;
    const reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
    const res = reg.test(this.userBaseInfo.password.value);
    if (!res) return true;
    if (this.userBaseInfo.password.value !== this.confirmPassword.value) return true;

    return false;
  }
}
